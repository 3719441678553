<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Ventas / Listado de Proformas</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h2>
        <strong
          >COTIZACIÓN # {{ proforma.numero }} -
          <span :class="claseEstado[proforma.estado] + ' product-badge ml-2'">{{
            proforma.estado_texto
          }}</span>
        </strong>
      </h2>
    </div>
    <div
      class="grid border-bottom-3"
      style="
        background-color: #f8f9fa;
        border-radius: 5px;
        border: 1px solid black;
      "
    >
      <div class="col-4">
        <p class="mb-0">
          <strong>CLIENTE: </strong>
          <span>{{ proforma.cliente }}</span>
        </p>
        <p class="mb-0">
          <strong>MERCADO: </strong>
          <span>{{ proforma.nombre_mercado ?? "" }} - {{ proforma.zona }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="mb-0">
          <strong>FECHA DE REGISTRO: </strong>
          <span>{{ proforma.fecha }}</span>
        </p>

        <p class="mb-0">
          <strong>USUARIO: </strong>
          <span>{{ proforma.vendedor }}</span>
        </p>
      </div>
      <div class="col-4 text-right">
        <p class="mb-0">
          <strong>V&Aacute;LIDO HASTA: </strong>
          <span>{{ dateValides(proforma.valido_hasta) ?? "No definido" }}</span>
        </p>
        <p class="mb-0">
          <strong>F. RESERVA: </strong>
          <span>{{ formatDate(proforma.reserva) ?? "-" }}</span>
        </p>
      </div>
    </div>
    <div class="grid mt-4">
      <DataTable
        ref="ventas"
        :value="productos"
        class="col-12 p-datatable-sm"
        showGridlines
        style="
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          line-height: 1.5;
        "
      >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <!-- <Column field="codigo_interno" header="CÓD">
          {{ producto.codigo_interno }}
        </Column> -->
        <Column field="pivot.cantidad" header="CANT. KG">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad *0) }}
          </template>
        </Column>
        <Column field="pivot.unidades" header="UNIDADES">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.unidades) }}
          </template>
        </Column>
        <Column field="fabrica_nombre" header="LABORATORIO" v-if="false">
          {{ producto.fabrica_nombre ?? "-" }}
        </Column>
        <Column
          field="descripcion"
          header="PRODUCTO/DESCRIPCION"
          style="font-weight: bold"
        >
          {{ producto.descripcion }}
        </Column>
        <Column field="unidad_medida_nombre" header="U.MEDIDA"> </Column>
        <Column
          v-if="false"
          field="pivot.fecha_vence"
          header="FVENCE"
          style="text-align: center"
        >
          <template #body="slotProps">
            {{
              slotProps.data.pivot.fecha_vence == "1905-03-14"
                ? "-"
                : slotProps.data.pivot.fecha_vence
            }}
          </template>
        </Column>
        <Column
          field="pivot.lote"
          header="LOTE"
          style="text-align: center"
          v-if="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.pivot.lote ?? "-" }}
          </template>
        </Column>
        <Column
          field="pivot.precio"
          header="PRECIO x KG"
          style="text-align: left"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
        </Column>
       <!-- <Column
          field="pivot.descuento_neto"
          header="DESC."
          style="text-align: right"
        >
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.pivot.descuento_neto)
            }}
          </template>
        </Column>-->
        <Column header="SUBTOTAL" style="text-align: right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.subtotal -
                  slotProps.data.pivot.descuento_neto
              )
            }}
          </template>
        </Column>
        <template #footer>
         <!-- <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>SUBTOTAL:</strong>
              {{ convertirNumeroGermanicFormat(subtotal) }}
            </h5>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>DESCUENTO:</strong>
              {{ convertirNumeroGermanicFormat(descuento) }}
            </h5>
          </div>-->
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>TOTAL PROFORMA:</strong>
              {{ convertirNumeroGermanicFormat(subtotal - descuento) }}
            </h5>
          </div>
        </template>
      </DataTable>
    </div>
    <div class="grid mt-2 justify-content-end">
      <Button @click="goBack" class="p-button-lg p-button-primary">
        <i class="pi pi-arrow-circle-left mr-1"></i>
        &nbsp;ATR&Aacute;S
      </Button>
      <Button
        label="IMPRIMIR"
        icon="pi pi-file-pdf"
        class="p-button-danger ml-2 p-button-lg"
        v-tooltip.top="'Imprimir PDF'"
        @click="imprimirVenta(proforma.id)"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import ProformaService from "@/service/ProformaService";
import VentaService from "@/service/VentaService";

export default {
  data() {
    return {
      proforma: {},
      productos: [],
      proforma_id: this.$route.params.id,
      imprimiendo: false,
      claseEstado: [
        "status-anulada", // 0: Anulada
        "status-vigente", // 1: Vigente
        "status-vendida", // 2: Vendida
        "status-vencida", // 3: Vencida
      ],
    };
  },
  proformaService: null,
  ventaService: null,
  created() {
    this.proformaService = new ProformaService();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarProforma();
  },
  computed: {
    subtotal() {
      let subtotal = 0;
      /*this.productos.forEach((producto) => {
        subtotal += producto.pivot.precio * producto.pivot.cantidad;
      });*/
      return subtotal;
    },
    descuento() {
      let descuento_neto = 0;
      /*this.productos.forEach((producto) => {
        descuento_neto += parseFloat(producto.pivot.descuento_neto);
      });*/
      return descuento_neto;
    },
    /* proforma_vencida() {
      if (this.proforma.valido_hasta) {
        let fecha = this.normalizarFecha(new Date(this.proforma.valido_hasta));
        let hoy = this.normalizarFecha(new Date());
        if (fecha < hoy) {
          return true;
        }
      }
      return false;
    }, */
  },
  methods: {
    normalizarFecha(fecha) {
      fecha.setHours(0, 0, 0, 0);
      return fecha;
    },
    dateValides(fecha) {
      if (fecha) {
        let date = new Date(fecha);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }
      return null;
    },
    formatDate(fecha) {
      if (!fecha) return "";
      let fecha_array = fecha.split("-");
      return `${fecha_array[2]}/${fecha_array[1]}/${fecha_array[0]}`;
    },
    cargarProforma() {
      this.proformaService.buscarProforma(this.proforma_id).then((response) => {
        this.proforma = response.proforma || {};
        this.productos = response.proforma.detalles || [];
      });
    },
    imprimirVenta(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.ventaService.imprimirVentaProforma(id).then(() => {
        this.imprimiendo = false;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.product-badge {
  padding: 3px 5px;
  border-radius: 2px;
  font-weight: bold;
  color: white;
}

.status-anulada {
  background-color: red;
}

.status-vigente {
  background-color: green;
}

.status-vendida {
  background-color: orange;
}

.status-vencida {
  background-color: darkcyan;
}
</style>
